.header-logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url('../../assets/header-doves.png');

}

.menu-logo {
  background-image: url('../../assets/menu-doves.png');
  height: 50px;
  background-repeat: no-repeat;
  background-position: center; 
  background-size: contain;
}


.intro-logo {
  background-image: url('../../assets/intro-doves.png');
  background-repeat: no-repeat;
  background-position: center; 
  margin-top:10%;
  background-size: contain;
}