@import "./doves/custom.scss";

/* ionic tags */
ion-button {
  text-transform: none;
}

ion-searchbar {
  --background: var(--ion-color-light) !important;
}

ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

a {
  text-decoration: none;
  color: var(--ion-color-secondary) !important;
}

input:read-only,
textarea:read-only {
  color: #ccc !important;
}

.gray-bottom-border {
  border-bottom: solid 1px #eee;
}

.validation-errors {
  font-size: small;
  color: var(--ion-color-danger, #f1453d);
}

.intro-logo {
  height: 200px;
}

.header-logo {
  height: 40px;
}

.intro-logo strong {
  font-size: 20px;
  line-height: 26px;
}

.intro-logo p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

.subtitle {
  color: var(--ion-color-secondary) !important;
}











.name-container ul{
  list-style: none;
  min-width: 200px;
  padding: 0;
}

.name-container ul li {
  list-style: none;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  align-items: center;
}

.name-container ul li .name {
  color: #000 !important;
  font-size: 16px ;
  font-weight: 500;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.name:hover  {
  background-color: #f4f4f4;

}


.info-container {
  width: 400px;
}


.info-container h3{
  font-weight: 600;
  color: #96232E;
  padding-bottom: 5px;
  font-size: 18px;
}


.button-container {
  display: flex;
  gap: 20px;
  padding: 20px 0;
  width: 100%;
}

.btn {
  width: 100px;
  height: 30px;
  background-color: #96232E;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 3px;
}


@media screen and (max-width: 500px) {
  .gm-style .gm-style-iw-c {
    max-width: 300px !important;
    min-width: 200px !important;
  }

  .name-container ul li {
    list-style: none;
    width: 100%;
    height: 28px;
    border-bottom: 1px solid #f4f4f4;
    display: flex;
    align-items: center;
  }

  .info-container {
    width: 100%;
  }
}



